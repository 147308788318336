import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shape, bool } from 'prop-types';
import { withPrefix, navigate } from 'gatsby';
import lottie from 'lottie-web';

import { SET_FROM_WEBSITE } from '../../actions/actionTypes';
import { isOnMobile } from '../../utils/detectDevice';
import classNames from 'classnames';

import './styles.styl';

class RegisterSuccess extends Component {
  constructor(props) {
    super(props);
    this.animationNode = React.createRef();
    this.isOnMobile = isOnMobile();
  }

  componentDidMount() {
    this.animation = lottie.loadAnimation({
      container: this.animationNode.current,
      render: 'svg',
      loop: false,
      autoplay: true,
      path: withPrefix('/animations/logo.json'),
    });
  }

  navigateBack = () => {
    const { cart, fromWebsite, resetFromWebsite } = this.props;

    if (fromWebsite) {
      resetFromWebsite();
      if (cart.items.length) {
        navigate('checkoutShipping');
      } else {
        navigate('/');
      }
    } else {
      window.location.replace('https://www.monimalz.com/app');
    }
  };

  buttonWording() {
    const { cart, fromWebsite, wordings } = this.props;

    if (fromWebsite) {
      if (cart.items.length) {
        return wordings.button_cart;
      } else {
        return wordings.button;
      }
    } else if (!fromWebsite && !this.isOnMobile) {
      return wordings.app_indication;
    } else {
      return wordings.button_app;
    }
  }

  render() {
    const { wordings, fromWebsite } = this.props;
    const buttonClass = classNames({
      button: true,
      disabled: !fromWebsite && !this.isOnMobile,
    });

    return (
      <div className="register-success">
        <div
          className="logo-player"
          ref={this.animationNode}
          onClick={this.navigateBack}
        />
        <div className="wrapper">
          <div>
            <h1>{wordings.title}</h1>
            <h2>{wordings.subtitle}</h2>
          </div>
          <div>
            <button
              type="button"
              className={buttonClass}
              onClick={this.navigateBack}
              disabled={!fromWebsite && !this.isOnMobile}
            >
              {this.buttonWording()}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

RegisterSuccess.defaultProps = {
  cart: {},
};

RegisterSuccess.propTypes = {
  cart: shape({}),
  fromWebsite: bool.isRequired,
};

const mapStateToProps = ({ shop: { cart }, app: { fromWebsite } }) => ({
  cart,
  fromWebsite,
});

const mapDispatchToProps = dispatch => {
  return {
    resetFromWebsite: () => {
      dispatch({ type: SET_FROM_WEBSITE, payload: { fromWebsite: false } });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterSuccess);
