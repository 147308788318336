import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import RegisterSuccess from '../components/registerSuccess/registerSuccess';
import Layout from '../components/layout/layout';

const SuccessPage = ({ data }) => (
  <Layout
    title={data.wordings.fr.register_success.page_title}
    description={data.wordings.fr.register_success.description}
  >
    <RegisterSuccess wordings={data.wordings.fr.register_success} />
  </Layout>
);

export default SuccessPage;

SuccessPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export const query = graphql`
  query SuccessPage {
    wordings {
      fr {
        register_success {
          page_title
          description
          title
          subtitle
          button
          button_cart
          button_app
          app_indication
        }
      }
    }
  }
`;
